import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  Grid,
  Box,
  TablePagination,
  Container,
  Button,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { makeAuthenticatedApiCall } from '../../apiHelper';
import { formatDateToDDMMYYYY } from '../../utils';
import AgencyInfoDialog from './AgencyInfoDialog'; // Import the new component

const stickyCellStyle = {
  background: '#262626',
  fontWeight: 'bold',
  minWidth: 50,
  color: 'white',
  position: 'sticky',
  top: 0,
  zIndex: 100,
};

const CGPendingInvoicesList = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [rows, setRows] = useState<any[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);
  const [selectedClientId, setSelectedClientId] = useState<string | null>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await makeAuthenticatedApiCall('get', '/api/qonto/pending-invoices');
        setRows((response.data as any[]) || []);
      } catch (error) {
        console.error('Error fetching pending invoices:', error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpen = (clientId: string) => {
    setSelectedClientId(clientId);
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
    setSelectedClientId(null);
  };

  if (isLoading) {
    return (
      <Grid container direction="column" justifyContent="center" alignItems="center" marginTop={2}>
        <Typography sx={{ marginBottom: 2 }} variant="body1">
          Chargement
        </Typography>
        <CircularProgress size={70} />
      </Grid>
    );
  }

  return (
    <Container maxWidth={false}>
      <Box>
        <Button
          variant="contained"
          color="primary"
          startIcon={<ArrowBackIcon />}
          onClick={() => window.history.back()} // Use history.back() to go back
          sx={{ marginBottom: 2, color: 'white', marginTop: 4 }}
        >
          Retour
        </Button>
        <Paper sx={{ marginTop: 5, padding: 0 }} elevation={3}>
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={stickyCellStyle}>Client ID</TableCell>
                  <TableCell sx={stickyCellStyle}>Pipedrive Deal ID</TableCell>
                  <TableCell sx={stickyCellStyle}>Pipeline Letter</TableCell>
                  <TableCell sx={stickyCellStyle}>VAT Rate</TableCell>
                  <TableCell sx={stickyCellStyle}>Selling Price</TableCell>
                  <TableCell sx={stickyCellStyle}>Reg Plate</TableCell>
                  <TableCell sx={stickyCellStyle}>Created At</TableCell>
                  <TableCell sx={stickyCellStyle}>Processed</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                  <TableRow key={`${row.id}_${index}`}>
                    <TableCell
                      onClick={() => handleOpen(row.client_id)}
                      style={{ cursor: 'pointer', fontWeight: '600' }}
                    >
                      {row.client_id}
                    </TableCell>
                    <TableCell>{row.pipedrive_deal_id}</TableCell>
                    <TableCell>{row.pipeline_letter}</TableCell>
                    <TableCell>{row.vat_rate}</TableCell>
                    <TableCell>{row.selling_price}</TableCell>
                    <TableCell>{row.reg_plate}</TableCell>
                    <TableCell>{formatDateToDDMMYYYY(row.created_at)}</TableCell>
                    <TableCell>{row.processed ? 'Yes' : 'No'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <AgencyInfoDialog clientId={selectedClientId} open={dialogOpen} onClose={handleClose} />
    </Container>
  );
};

export default CGPendingInvoicesList;

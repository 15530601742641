import { useEffect, useState } from 'react';
import { Grid, Typography, TextField, Button, Divider, CircularProgress, Box } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import FirebaseUploadComponent from '../CreateCotation/FirebaseUploadComponent';
import { CarDeal } from '../../types/deal';
import { makeAuthenticatedApiCall } from '../../apiHelper';
import axios from 'axios';
import FilesOpener from '../CGForm/FilesOpener';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';

// Définition des types de props
interface EvoCommentProps {
  deal: CarDeal;
  userType: 'evo' | 'agency';
}

const EvoComment: React.FC<EvoCommentProps> = ({ deal, userType }) => {
  const {
    control,
    formState: { errors, isSubmitSuccessful },
    handleSubmit,
    setError,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      comment: '',
      answer: '',
    },
  });

  const theme = useTheme();
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  // State to manage uploaded URLs
  const [uploadedUrls, setUploadedUrls] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [isFieldsDisabled, setIsFieldsDisabled] = useState(false);
  const [hasExistingComment, setHasExistingComment] = useState(false);
  const [hasExistingAnswer, setHasExistingAnswer] = useState(false);
  const [commentDate, setCommentDate] = useState<string>('');
  const [answerDate, setAnswerDate] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);

  const commentValue = watch('comment'); // Watch the comment field value
  const answerValue = watch('answer'); // Watch the answer field value

  useEffect(() => {
    // if (userType === 'evo') {
    //   loginAutomatically();
    // }

    if (userType === 'agency' && !currentUser) {
      setIsLoading(true); // Start loading
      const checkUserInterval = setInterval(() => {
        if (currentUser) {
          clearInterval(checkUserInterval);
          setIsLoading(false); // Stop loading when user is found
        } else {
          console.log('Checking if currentUser is not null...');
        }
      }, 1000);

      const timeoutId = setTimeout(() => {
        clearInterval(checkUserInterval);
        if (!currentUser) {
          navigate('/login', { state: { from: location.pathname + location.search } });
        }
        setIsLoading(false); // Stop loading after redirect
      }, 3000);

      // Cleanup function to clear the interval and timeout
      return () => {
        clearInterval(checkUserInterval);
        clearTimeout(timeoutId);
        setIsLoading(false); // Ensure loading stops on cleanup
      };
    }
    fetchExistingComments();
    setIsLoading(false);
    console.log('currentUser', currentUser);
  }, [currentUser, userType, navigate]);

  // Effect to block/unblock scrolling
  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = 'hidden'; // Disable scrolling
    } else {
      document.body.style.overflow = ''; // Reset scrolling
    }

    // Cleanup function to reset scrolling when component unmounts
    return () => {
      document.body.style.overflow = '';
    };
  }, [isLoading]);

  // Function to handle upload completion
  const handleUploadComplete = (url: string) => {
    try {
      setUploadedUrls((prevUrls) => [...prevUrls, url]);
    } catch (error) {
      console.error('Error during upload:', error);
    }
  };

  // Function to handle delete completion
  const handleDeleteComplete = (url: string) => {
    setUploadedUrls((prevUrls) => prevUrls.filter((prevUrl) => prevUrl !== url));
  };

  /*
  const loginAutomatically = async () => {
    if (!isFromExternal) {
      try {
        signOut(auth).then(async () => {
          const automaticConnectResult = await signInWithEmailAndPassword(
            auth,
            'prospection.skipcar@gmail.com',
            import.meta.env.VITE_SC_API_KEY,
          );
          if (automaticConnectResult.user.email !== 'prospection.skipcar@gmail.com') {
            await signOut(auth);
          }
        });
      } catch (error) {
        console.error('Error during automatic authentication:', error);
      }
    }
  };
  */

  const fetchExistingComments = async () => {
    try {
      const response = await axios.get(`/api/evo/comment/${deal.pipedrive_deal_id}`);
      if (response.data.success && response.data.evoComment.length > 0) {
        const evoComment = response.data.evoComment.find((comment: any) => comment.sender === 'evo');
        const agencyComment = response.data.evoComment.find((comment: any) => comment.sender === 'agency');

        if (evoComment) {
          setValue('comment', evoComment.comment);
          setCommentDate(new Date(evoComment.created).toLocaleString());
          setHasExistingComment(true);
        }

        if (agencyComment) {
          setValue('answer', agencyComment.comment);
          setUploadedUrls(JSON.parse(agencyComment.uploaded_urls || '[]'));
          setAnswerDate(new Date(agencyComment.created).toLocaleString());
          setHasExistingAnswer(true);
        }

        setIsFieldsDisabled(userType === 'evo' ? hasExistingComment : hasExistingAnswer);
      }
    } catch (error) {
      console.error('Error fetching existing comment:', error);
    }
  };

  console.log('deal', deal);
  const onSubmit = async (data: any) => {
    setIsSubmitting(true);
    try {
      await axios.post('/api/evo/comment', {
        dealId: deal.pipedrive_deal_id,
        userType,
        ...data,
        uploadedUrls,
      });
      setSuccessMessage('Commentaire envoyé avec succès');
      setIsFieldsDisabled(true);
    } catch (error) {
      console.error('Error submitting comment:', error);
      setError('comment', {
        type: 'manual',
        message: "Erreur lors de l'envoi du commentaire.",
      });
    }
    if (userType === 'evo') {
      try {
        await axios.post('/api/mails/evo/comment', {
          dealId: deal.pipedrive_deal_id,
          orgId: deal.org_id,
          regPlate: deal.registration_plate,
          userType,
          make: deal.make,
          model: deal.model,
          comment: commentValue,
          url: window.location.href.replace(/datastore=[^&]+/, `datastore=${deal.spark_fds_encrypt}`),
        });
      } catch (error) {
        console.error('Error submitting email / ', error);
      }
    }
    if (userType === 'agency') {
      // Toute la logique liée à la duplication du deal et à son envoi à EVO

      try {
        const response = await makeAuthenticatedApiCall('post', '/api/evo/spark', {
          deal,
          evoCommentsUploadedUrls: uploadedUrls,
        });

        if ((response.data as any).fds_encrypt) {
          console.log('Duplicata créé avec succès:', (response.data as any).fds_encrypt);
        }
      } catch (error) {
        console.error('Erreur lors de la création du duplicata du deal:', error);
      }
    }
    setIsSubmitting(false);
  };

  return (
    <Grid container style={{ marginTop: 24, width: 'calc(100% - 24px)' }}>
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
            scroll: 'none',
            overflow: 'hidden',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginLeft: 24, width: 'calc(100% - 24px)' }}>
        <Divider
          variant="fullWidth"
          component="hr"
          style={{
            marginLeft: 24,
            marginTop: 15,
            backgroundColor: 'black',
            height: '2px',
            width: 'calc(100% - 24px)',
            border: 0,
          }}
        />

        <Grid container spacing={2}>
          <Grid item xs={12} md={12} style={{ marginTop: 15 }}>
            <Typography variant="body1" fontSize={22} color={theme.palette.saffron.main}>
              Commentaires / Remarques / Échanges
            </Typography>
          </Grid>
          {userType === 'evo' && (
            <>
              <Grid item xs={12} md={6}>
                <Typography variant="body1">Commentaire (EVO) {hasExistingComment && `- ${commentDate}`}</Typography>
                {hasExistingComment ? (
                  <Typography variant="body2" className="inputElement">
                    {commentValue}
                  </Typography>
                ) : (
                  <Controller
                    name="comment"
                    control={control}
                    rules={{ required: 'Ce champ est requis' }}
                    render={({ field }) => (
                      <TextField
                        id="comment-label"
                        error={!!errors.comment}
                        helperText={errors.comment ? errors.comment.message : ''}
                        fullWidth
                        {...field}
                        value={commentValue} // Keep the comment visible
                        placeholder="La cotation pose problème car ..."
                        multiline
                        rows={4}
                        className="inputElement"
                        disabled={isFieldsDisabled || isSubmitSuccessful} // Disable after successful submission
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6}></Grid>
            </>
          )}
          {userType === 'agency' && (
            <>
              <Grid item xs={12} md={6}>
                <Typography variant="body1">Commentaire (EVO) {hasExistingComment && `- ${commentDate}`}</Typography>
                <Typography variant="body2" className="inputElement">
                  {commentValue}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}></Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1">Réponse (Agence) {hasExistingAnswer && `- ${answerDate}`}</Typography>
                {hasExistingAnswer ? (
                  <Typography variant="body2" className="inputElement">
                    {answerValue}
                  </Typography>
                ) : (
                  <Controller
                    name="answer"
                    control={control}
                    rules={{ required: 'Ce champ est requis' }}
                    render={({ field }) => (
                      <TextField
                        id="answer-label"
                        error={!!errors.answer}
                        helperText={errors.answer ? errors.answer.message : ''}
                        fullWidth
                        {...field}
                        value={answerValue} // Keep the answer visible
                        placeholder="Votre réponse ici..."
                        multiline
                        rows={4}
                        className="inputElement"
                        disabled={isFieldsDisabled || isSubmitSuccessful} // Disable after successful submission
                      />
                    )}
                  />
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="body1">
                  {hasExistingAnswer ? 'Fichier(s) uploadé(s)' : 'Uploader un fichier'}
                </Typography>
                {hasExistingAnswer ? (
                  <FilesOpener FilesUrls={uploadedUrls.join(',')} />
                ) : (
                  <FirebaseUploadComponent
                    onUploadComplete={handleUploadComplete}
                    onDeleteComplete={handleDeleteComplete}
                    uploadedUrls={uploadedUrls}
                    source={currentUser?.uid}
                  />
                )}
              </Grid>
            </>
          )}
        </Grid>

        <Grid item xs={12} md={12} style={{ marginTop: '20px' }}>
          {((userType === 'evo' && !hasExistingComment) || (userType === 'agency' && !hasExistingAnswer)) && (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{
                backgroundColor: theme.palette.saffron.main,
                color: 'white',
                fontWeight: 600,
              }}
              disabled={isSubmitting || isSubmitSuccessful || isFieldsDisabled}
            >
              {isSubmitting ? 'Envoi...' : userType === 'agency' ? 'Répondre' : "Envoyer à l'agence"}
            </Button>
          )}
          {successMessage && (
            <Typography variant="body2" color="success" align="center">
              {successMessage}
            </Typography>
          )}
        </Grid>
      </form>
    </Grid>
  );
};

export default EvoComment;

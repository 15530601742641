import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  Button,
  Grid,
  Box,
  TablePagination,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { makeAuthenticatedApiCall } from '../apiHelper';
import theme from '../theme';
import { formatDateToDDMMYYYY } from '../utils';
import AddClientModal from './CGBillingClientList/AddClientModal';
import DownloadInvoices from './CGList/DownloadInvoices';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import AgencyInfoDialog from './CGBillingClientList/AgencyInfoDialog';

const stickyCellStyle = {
  background: '#262626',
  fontWeight: 'bold',
  minWidth: 50,
  color: 'white',
  position: 'sticky',
  top: 0,
  zIndex: 100,
};

const CGBillingClientList = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [rows, setRows] = useState<any[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);
  const [open, setOpen] = useState<boolean>(false);
  const [clientInvoices, setClientInvoices] = useState<any>(null);
  const [qontoInvoicing, setQontoInvoicing] = useState<any>(null);
  const [clientData, setClientData] = useState<any>(null);
  const [selectedClientId, setSelectedClientId] = useState<string | null>(null);
  const [selectedAgencyName, setSelectedAgencyName] = useState<string | null>(null); // New state for agency name
  const [addClientOpen, setAddClientOpen] = useState<boolean>(false); // State for AddClientModal
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const { currentUser } = useAuth();
  const userRole = currentUser?.data?.data?.role;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await makeAuthenticatedApiCall('get', '/api/qonto/billing-client-list');
        setRows((response.data as any[]) || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      }

      try {
        const response = await makeAuthenticatedApiCall('get', '/api/qonto/qonto-invoicing-list');
        console.log('response', JSON.stringify(response.data));
        setQontoInvoicing((response.data as any[]) || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      }

      setIsLoading(false);
    };

    fetchData();
  }, []);

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpen = (clientId: string) => {
    setSelectedClientId(clientId);
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
    setSelectedClientId(null);
  };

  const getInvoicingValue = (clientId: string) => {
    const invoicing = qontoInvoicing.find(
      (item: { client_id: string; invoicing: string }) => item.client_id === clientId,
    );
    return invoicing ? invoicing.invoicing : 'One-time';
  };

  const handleInvoicingChange = async (clientId: string, invoicing: string) => {
    try {
      await makeAuthenticatedApiCall('post', '/api/qonto/update-invoicing', {
        clientId,
        invoicing,
      });
      console.log(`Invoicing updated for client ${clientId} to ${invoicing}`);
      setQontoInvoicing((prev: any[]) => {
        const existing = prev.find((item) => item.client_id === clientId);
        if (existing) {
          return prev.map((item) => (item.client_id === clientId ? { ...item, invoicing } : item));
        } else {
          return [...prev, { client_id: clientId, invoicing }];
        }
      });
    } catch (error) {
      console.error('Error updating invoicing:', error);
    }
  };

  if (isLoading) {
    return (
      <Grid container direction="column" justifyContent="center" alignItems="center" marginTop={2}>
        <Typography sx={{ marginBottom: 2 }} variant="body1">
          Chargement
        </Typography>
        <CircularProgress size={70} />
      </Grid>
    );
  }

  return (
    <Container maxWidth={false}>
      <Grid container justifyContent="space-between" alignItems="center">
        {userRole !== 'agency' && <DownloadInvoices />}
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/pending-invoices')}
          sx={{
            marginLeft: 1,
            fontWeight: 900,
            fontSize: 12,
            marginTop: 4,
            height: 33,
            backgroundColor: theme.palette.saffron.main,
            '&:hover': { backgroundColor: theme.palette.saffron.main },
          }}
        >
          Factures en cours
        </Button>
      </Grid>
      <Box>
        <Paper sx={{ marginTop: 5, padding: 0 }} elevation={3}>
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={stickyCellStyle}>Client ID</TableCell>
                  <TableCell sx={stickyCellStyle}>Agence</TableCell>
                  <TableCell sx={stickyCellStyle}>E-mail(s)</TableCell>
                  <TableCell sx={stickyCellStyle}>Facturation</TableCell>
                  <TableCell sx={stickyCellStyle}>Nb de deals</TableCell>
                  <TableCell sx={stickyCellStyle}>Nb de véhicules</TableCell>
                  <TableCell sx={stickyCellStyle}>Nb de factures</TableCell>
                  <TableCell sx={stickyCellStyle}>Statuts</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                  <TableRow key={`${row.client_id}_${index}`}>
                    <TableCell
                      onClick={() => handleOpen(row.client_id)}
                      style={{ cursor: 'pointer', fontWeight: '600' }}
                    >
                      {row.client_id}
                    </TableCell>
                    <TableCell>{row.AgencyName}</TableCell>
                    <TableCell>{row.AgencyEmails}</TableCell>
                    <TableCell>
                      <Select
                        value={getInvoicingValue(row.client_id)}
                        onChange={(e) => handleInvoicingChange(row.client_id, e.target.value)}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        className="inputElement"
                      >
                        <MenuItem className="inputElement" value="One-time">
                          Ponctuelle
                        </MenuItem>
                        <MenuItem className="inputElement" value="Bi-weekly">
                          Quinzaine
                        </MenuItem>
                        <MenuItem className="inputElement" value="Monthly">
                          Mensuelle
                        </MenuItem>
                      </Select>
                    </TableCell>
                    <TableCell>{row.PipedriveDealsCount}</TableCell>
                    <TableCell>{row.VehicleIDsCount}</TableCell>
                    <TableCell>{row.InvoiceNumbersCount}</TableCell>
                    <TableCell>{row.InvoiceStatuses}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <AgencyInfoDialog clientId={selectedClientId} open={dialogOpen} onClose={handleClose} />
    </Container>
  );
};

export default CGBillingClientList;
